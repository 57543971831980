import PropTypes from 'prop-types';
import Button from '../components/Button';

import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

import closeIcon from '../assets/img/icons/x.svg';
import "../styles/Modal/Modal.css";

function Modal({ isOpen, toggleModal }) {
    const form = useRef();

    const submitForm = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_hheu9xq', 'template_qorghp3', form.current, '0BQyXIBurXoq25SYB')
            .then((result) => {
                alert("Le message a été envoyé !");
            }, (error) => {
                alert("Une erreur est survenue lors de l'envoi du mail.");
            });
    };

    return (
        <div className={`modal__container ${isOpen ? "modal__container--opened" : "modal__container--closed"}`}>
            <div className="modal">
                <button className="modal__close" onClick={toggleModal}>
                    <img src={closeIcon} alt="Close Icon" />
                </button>

                <p className="modal__title">Me contacter</p>

                <form className="modal__form" ref={form} onSubmit={submitForm}>
                    <div className="modal__form--input">
                        <label htmlFor="email">Adresse e-mail</label>
                        <input type="email" name="email" id="email" placeholder="Ex.: johndoe@mail.com" required />
                    </div>

                    <div className="modal__form--input">
                        <label htmlFor="object">Objet</label>
                        <input type="text" name="object" id="object" placeholder="Ex.: Refonte d'un site vitrine" required />
                    </div>

                    <div className="modal__form--input">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" placeholder="Votre message..." required />
                    </div>
                    
                    <div className="modal__form--submit">
                        <Button className="btn btn-primary" type="submit">Envoyer</Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

Modal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleModal: PropTypes.func.isRequired
};

export default Modal;