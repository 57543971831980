import { useState } from 'react';

import Header from '../components/Header';
import Footer from '../components/Footer';
import Profile from '../components/Profile';
import Project from '../components/Project';

import projects from '../projects.json';

function Home() {
    const [currentPage, setCurrentPage] = useState(0);
    const projectsPerPage = 3;

    const totalPages = Math.ceil(projects.length / projectsPerPage);

    const changePage = page => {
        setCurrentPage(page);
    };

    const renderPageNumbers = Array.from({ length: totalPages }, (_, i) => i);

    return (
        <>
            <Header />

            <main>
                <Profile />

                <section id="projects" className="project">
                    <h3 className="project__title">Mes projets</h3>

                    <article className="project__list">
                        {
                            projects.slice(currentPage * projectsPerPage, (currentPage + 1) * projectsPerPage).map((project, index) => (
                                <Project
                                    key={index}
                                    title={project.title}
                                    subtitle={project.subtitle}
                                    text={project.text}
                                    image={project.image}
                                    links={project.links}
                                />
                            ))
                        }
                    </article>

                    <div className="project__pagination">
                    {
                        renderPageNumbers.map(page => (
                            <button key={page} className={currentPage === page ? "btn-pagination btn-pagination--active" : "btn-pagination"} onClick={() => changePage(page)}>
                                {page + 1}
                            </button>
                        ))
                    }
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
}

export default Home;