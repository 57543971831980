import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Menu from "../components/Menu";

import githubIcon from "../assets/img/icons/github.svg";
import linkedinIcon from "../assets/img/icons/linkedin.svg";
import hamburgerMenuIcon from "../assets/img/icons/menu.svg";

import '../styles/Header/Header.css';

function Header() {
    const location = useLocation();
    const [isOpenMenu, setIsOpenMenu] = useState(false);

    const toggleMenu = () => {
        setIsOpenMenu(!isOpenMenu);
    }

    return (
        <header>
            <nav className="navbar">
                <ul className="navbar__link navbar__link--anchor">
                    <li><Link className={location.pathname === '/' ? "navbar__link--purple" : ""} to="/">Accueil</Link></li>
                    <li><Link className={location.pathname === '/about' ? "navbar__link--purple" : ""} to="/about">A propos</Link></li>
                </ul>
                <h1 className="navbar__title">Damien Druaux</h1>
                <ul className="navbar__link navbar__link--socialnetwork">
                    <li>
                        <Link to="https://www.linkedin.com/in/damien-d-576a98269/" target="_blank">
                            <img src={linkedinIcon} alt="Linkedin Icon" />
                        </Link>
                    </li>
                    <li>
                        <Link to="https://github.com/Mylaano" target="_blank">
                            <img src={githubIcon} alt="GitHub Icon" />
                        </Link>
                    </li>
                </ul>

                <button className="navbar__hamburger" onClick={toggleMenu}>
                    <img src={hamburgerMenuIcon} width="24px" height="24px" alt="Hamburger Menu" />
                </button>

                <Menu isOpen={isOpenMenu} toggleMenu={toggleMenu} />
            </nav>
        </header>
    )
}

export default Header;