import Header from '../components/Header';
import Footer from '../components/Footer';

import "../styles/NotFound/NotFound.css";

function NotFound() {
    return (
        <>
            <Header />

            <main>
                <section className="notfound">
                    <h2 className="notfound__title">404</h2>
                    <p className="notfound__content">Une erreur est survenue, la page n’a pas été trouvée.</p>
                </section>
            </main>
            
            <Footer />
        </>
    );
}

export default NotFound;