import Header from '../components/Header';
import Footer from '../components/Footer';

import "../styles/About/About.css";

import picture from '../assets/img/damien-druaux-picture.webp';

const skillList = [
    "HTML/CSS", "JavaScript", "Sass", "React", "Redux", "Responsive", "GitHub", "Figma",
    "VS Code", "Windows", "Accessibilité", "Optimisation", "Linux", "SEO", "NodeJS", "ExpressJS", 
    "SQL", "API"
];

function About() {
    return (
        <>
            <Header />

            <figure className="biography">
                <img src={picture} alt="Damien Druaux" />
                <figcaption>
                    <h2>Enchanté Damien Druaux</h2>
                    <p>
                        Fort d'une expérience antérieure en tant que technicien informatique et d'une passion précoce pour cet univers ainsi que pour les nouvelles technologies, j'ai décidé de faire le saut dans le développement web.<br />
                        La maintenance ne suffisait plus à nourrir mon esprit créatif, c'est pourquoi j'ai décidé de suivre ma passion dans une direction nouvelle.<br /><br />
                        Ma formation de neuf mois sur la plateforme d'apprentissage en ligne OpenClassrooms m'a permis d'approfondir les connaissances que j'avais acquises durant mes années de collège. <br />
                        Aujourd'hui, je possède une solide expertise dans la conception de sites internet performants et dynamiques, en utilisant des technologies telles que React, Sass, NodeJS, et Redux.<br /><br />
                        Doté d'une forte créativité, je suis capable de concevoir des maquettes sur-mesure et de déployer de nouveaux projets grâce à mes compétences en développement front-end.
                    </p>
                </figcaption>
            </figure>

            <section className="biography-2">
                <article className="career">
                    <h2>Future carrière</h2>
                    <p>
                        Toujours animé par le désir constant de progresser et de créer des applications de plus en plus impressionnantes, complexes et innovantes. Ma passion pour l'apprentissage est inextinguible, et j'ai hâte de partager toutes mes découvertes à venir dans le vaste univers du développement !<br /><br />
                        Je souhaite me spécialiser en tant que développeur front-end, explorant une variété de technologies, et éventuellement exceller dans le domaine du développement logiciel à l'avenir.<br /><br />
                        Mon rêve est de progresser en tant que développeur de jeux vidéo et d'avoir l'opportunité de créer mon propre RPG.<br /><br />
                        C'est certainement un défi audacieux, mais seriez-vous prêt à contribuer à cette aventure ?
                    </p>
                </article>

                <ul className="skills">
                    {
                        skillList.map((skill, index) => (
                            <li key={index}>{skill}</li>
                        ))
                    }
                </ul>
            </section>
            
            <Footer />
        </>
    );
}

export default About;