import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './sass/main.css';

import Home from './pages/Home';
import About from './pages/About';
import NotFound from './pages/NotFound';
import LegalNotice from './pages/LegalNotice';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<Router>
            <Routes>
                <Route path="/" element={<Home />} />
				<Route path="/about" element={<About />} />
                <Route path="/legalnotice" element={<LegalNotice />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </Router>
	</React.StrictMode>
);