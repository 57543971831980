import { Link } from 'react-router-dom';
import '../styles/Footer/Footer.css';

function Footer() {
    return (
        <footer className="footer">
            <p className="footer__text">Designed and developed with passion - <Link to='/legalnotice' className="footer__link">Mentions légales</Link></p>
        </footer>
    );
}

export default Footer;