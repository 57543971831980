import { Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';

import closeIcon from '../assets/img/icons/x.svg';
import githubIcon from "../assets/img/icons/github.svg";
import linkedinIcon from "../assets/img/icons/linkedin.svg";

import "../styles/Menu/Menu.css";

function Menu({ isOpen, toggleMenu }) {
    const location = useLocation();

    return (
        <div className={isOpen ? "menu" : "menu menu--hide"}>
            <button className="menu__close" onClick={toggleMenu}>
                <img src={closeIcon} alt="Close Icon" />
            </button>

            <ul className="menu__link">
                <li><Link className={location.pathname === '/' ? "menu__link--purple" : ""} to="/">Accueil</Link></li>
                <li><Link className={location.pathname === '/about' ? "menu__link--purple" : ""} to="/about">A propos</Link></li>
            </ul>

            <ul className="menu__socialnetwork">
                <li>
                    <Link to="https://www.linkedin.com/in/damien-d-576a98269/" target="_blank">
                        <img src={linkedinIcon} alt="Linkedin Icon" />
                    </Link>
                </li>
                <li>
                    <Link to="https://github.com/Mylaano" target="_blank">
                        <img src={githubIcon} alt="GitHub Icon" />
                    </Link>
                </li>
            </ul>
        </div>
    );
}

Menu.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggleMenu: PropTypes.func.isRequired
};

export default Menu;