import PropTypes from 'prop-types';

function Button({ className, onClick, type, children }) {
    return (
        <button className={`btn ${className}`} type={type} onClick={onClick}>{children}</button>
    );
}

Button.propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.string,
    children: PropTypes.string.isRequired
};

export default Button;