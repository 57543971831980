import Header from '../components/Header';
import Footer from '../components/Footer';

import '../styles/LegalNotice/LegalNotice.css';

function LegalNotice() {
    return (
        <>
            <Header />

            <main>
                <section className="legalnotice">
                    <h2 className="legalnotice__title">Mentions Légales</h2>

                    <section className="legalnotice__content">
                        <article>
                            <h3>Éditeur du site</h3>
                            <ul>
                                <li>Damien Druaux</li>
                                <li>19 Rue Lenotre, 76140 Le Petit-Quevilly, France</li>
                                <li>06 61 03 84 13</li>
                                <li>contact@damien-druaux.fr</li>
                            </ul>
                        </article>

                        <article>
                            <h3>Directeur de la publication</h3>
                            <p>Damien Druaux</p>
                        </article>

                        <article>
                            <h3>Hébergement du site</h3>
                            <ul>
                                <li>Contabo GmbH</li>
                                <li>Aschauer Strasse 32a, 81549 Munich, Germany</li>
                                <li>+49 89 3564717 70</li>
                                <li>info@contabo.com</li>
                            </ul>
                        </article>

                        <article>
                            <h3>Propriété intellectuelle</h3>
                            <p>
                                Le contenu de ce site, y compris mais sans s'y limiter, les textes, les images, les graphiques, les logos et les fichiers audio, est la propriété exclusive de Damien Druaux et est protégé par les lois sur la propriété intellectuelle. 
                                Toute utilisation non autorisée du contenu de ce site est strictement interdite.
                            </p>
                        </article>

                        <article>
                            <h3>Cookies</h3>
                            <p>
                                Ce site peut utiliser des cookies pour améliorer l'expérience de l'utilisateur. 
                                En utilisant ce site, vous consentez à l'utilisation de cookies conformément à notre politique de cookies.
                            </p>
                        </article>

                        <article>
                            <h3>Liens vers des sites tiers</h3>
                            <p>
                                Ce site peut contenir des liens vers des sites web tiers. 
                                Damien Druaux n'est pas responsable du contenu ou des pratiques de confidentialité de ces sites.
                            </p>
                        </article>

                        <article>
                            <h3>Droit applicable et juridiction</h3>
                            <p>
                                Les présentes mentions légales sont régies par les lois en vigueur en France. 
                                Tout litige découlant de l'utilisation de ce site sera soumis à la juridiction exclusive des tribunaux de Rouen.
                            </p>
                        </article>

                        <article>
                            <h3>Contact</h3>
                            <p>Pour toute question concernant ces mentions légales, veuillez nous contacter à l'adresse suivante : contact@damien-druaux.fr.</p>
                        </article>
                    </section>

                    <p className="legalnotice__lastupdate">Dernière mise à jour : Le Mardi 24 octobre 2023</p>
                </section>
            </main>

            <Footer />
        </>
    );
}

export default LegalNotice;