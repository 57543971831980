import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

import githubIcon from "../assets/img/icons/github.svg";
import externalLinkIcon from "../assets/img/icons/external-link.svg";

import '../styles/Project/Project.css';

function Project({ title, subtitle, text, image, links }) {
    return (
        <figure>
            <img 
                className="project__list--picture" 
                src={image.src} 
                alt={image.alt} 
                srcSet={image.srcset}
                sizes={image.sizes} 
            />
            
            <div className="project__list--content">
                <h4 className="project__list--title">{title}</h4>
                <span className="project__list--subtitle">{subtitle}</span>
            </div>
            
            <p className="project__list--text">{text}</p>
            
            <div className="project__list--buttons">
                <Link className="btn btn-primary" to={links.code} target="_blank">
                    <img src={githubIcon} alt="GitHub Icon" />
                    Voir le code
                </Link>
                <Link className="btn btn-secondary" to={links.website} target="_blank">
                    <img src={externalLinkIcon} alt="External Link Icon" />
                    Visualiser
                </Link>
            </div>

        </figure>
    );
}

Project.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
    links: PropTypes.object.isRequired
};

export default Project;