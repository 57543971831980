import { useState } from 'react';

import Button from '../components/Button';
import Modal from '../components/Modal';

import picture from '../assets/img/damien-druaux-picture.webp';
import '../styles/Profile/Profile.css';

function Profile() {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <>
            <Modal isOpen={isModalOpen} toggleModal={toggleModal} />
            <section id="home" className="section__home">
                <figure>
                    <img src={picture} alt="Damien Druaux" />
                    <figcaption>
                        <p>Hello!</p>
                        <h2>
                            Je suis Damien Druaux.<br />
                            Développeur / Intégrateur<br />
                            Web basé en Normandie.
                        </h2>
                        <Button className="btn-primary section__home--contact" onClick={toggleModal}>Me contacter</Button>
                    </figcaption>
                </figure>
            </section>
        </>
    )
}

export default Profile;